import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import * as styles from "../../styles/page/top/feature.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

const RelatedProject = (props) => {
  const { projects } = useStaticQuery(graphql`
    query relatedProjectQuery {
      projects: allProjectsJson {
        edges {
          node {
            slug
            title
            description
            mainImage
            relatedResource {
              link
              title
            }
          }
        }
      }
    }
  `)
  return (
    props.items.map((item, id) => {
      const project = projects.edges.map( project => {
        const {slug, title} = project.node
        return (item === `/project/${slug}/`) ? <Link key={`project-${id}`} to={`/project/${slug}/`} className={styles.featureCard}>
          <div className={styles.featureCardImage}><img className={styles.Image} src={`/images/project/${slug}/og.png`} alt={title} /></div>
          <div className={styles.featureCardContent}>
            <h3 className={styles.featureCardHeading} dangerouslySetInnerHTML={{__html: specialCharacterReplace(title) }} />
            <span className={styles.featureCardBullet}>詳しく見る</span>
          </div>
        </Link> : ''
      })
      return project;
    })
  )
}

export default RelatedProject

