import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// 記事データ
import TopFeature from "../../components/parts/top/feature" // 特集
import ShareButtons from "../../components/parts/share-button"
import RelatedProject from "../../components/parts/related-project"

const FeatureArticlePage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const featureData = data.feature.edges

  const {
    title,
    slug,
    subTitle,
    mainImage,
    description,
    relatedProject
  } = frontmatter

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        ogImage={`https://${process.env.HOST_NAME}/images${slug}og.png`}
        path={slug}
      />

      <BreadCrumb
        parentLink="/article/"
        parentTitle="特集記事"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(`<span class=${styles.pageTitleSub}>${subTitle}</span>${title}`) }} />
      </div>

      <main className={styles.articleContents}>
        <article className={styles.articleContentsLeft}>
          <img className={styles.mainImage} src={mainImage} alt={title} />
          <div className={styles.articleFeatureSns}>
            <ShareButtons style={styles.articleSns} url={`https://${process.env.HOST_NAME}${slug}`} />
          </div>
          <div className={styles.articleInner} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(html)}} />
        </article>
        
        <aside className={`${styles.articleContentsRight} ${styles.featureAsideOrder}`}>
          <div className={styles.Article}>
            <div className={styles.pageTitle}>
              <h3 className={styles.pageTitleSmallHeading}>
                <span className={styles.pageTitleLine}>特集記事</span>
              </h3>
            </div>

            <div className={styles.featureAside}>
              {featureData.map( (item, id) => {
                return <TopFeature key={`top-feature-${id}`} index={id} data={item.node.frontmatter} summaryHide={true} />
              })}
            </div>
            <p className={styles.allLists}><Link className={styles.allListsBullet} to="/article/">全てを見る</Link></p>
          </div>

          {
            (relatedProject !== null) ? 
              <div className={styles.Project}>
                <div className={styles.pageTitle}>
                  <h3 className={styles.pageTitleSmallHeading}>
                    <span className={styles.pageTitleLine}>関連プロジェクト</span>
                  </h3>
                </div>

                <div className={styles.featureAside}>
                  <RelatedProject items={relatedProject} />
                </div>
                <p className={styles.allLists}><Link className={styles.allListsBullet} to="/project/">全てを見る</Link></p>
              </div> : ''
          }

          <div className={`${styles.Pamphlet} text-center`}>
            <a href="https://www.nedo.go.jp/content/100955752.pdf" target="_blank" rel="noopener noreferrer">
              <StaticImage src={'../../images/bnr-pamphlet.png'} alt="グリーンイノベーション基金事業パンフレット" placeholder="blurred" />
              <div className={styles.pamphletTitle}>グリーンイノベーション基金事業パンフレット</div>
            </a>
          </div>
        </aside>

      </main>
    </Layout>
  )
}
export default FeatureArticlePage

export const query = graphql`
  query($slug: String!) {
    feature: allMarkdownRemark(
      filter: {fields: {slug: {ne: $slug, regex: "/article/"}}}
      sort: { fields: [frontmatter___order], order: ASC }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            order
            mainImage
            slug
          }
        }
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
        subTitle
        date(formatString: "YYYY-MM-DD")
        mainImage
        relatedProject
      }
    }
  }
`